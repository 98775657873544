<template>

    <router-link to="/"><div class="cruz"><button type="button" class="close" aria-label="Close">
  <span aria-hidden="true">&times;</span>
</button></div></router-link>

    <div class="d-flex fullheight">


      <div class="align-self-center limitar">

      <div class="tarjeta launo">
    <div class="row d-flex">
      <div class="col-12">
        <img src="../assets/logoblanco.png" class="mb-4 logosub" />
      </div>
      <div class="col-sm-12 col-md-5 text-center align-self-center">
        <img :src="foto" class="mb-3 esfoto" />
      </div>
      <div class="col-sm-12 col-md-7">

        <h1 v-html="nombre" class="mt-0 mb-4"></h1>
        <div class="datos">
          <div class="row mb-2">
            <div class="col-4 mai">Nº de DNI</div>
            <div class="col-8 seg" v-html="dni"></div>
          </div>
          <div class="row mb-2">
            <div class="col-4 mai">Nacimiento</div>
            <div class="col-8 seg" v-html="fnac"></div>
          </div>
          <div class="row mb-2">
            <div class="col-4 mai">E-mail</div>
            <div class="col-8 seg" v-html="email"></div>
          </div>
          <div class="row mb-2">
            <div class="col-4 mai">Alta</div>
            <div class="col-8 seg" v-html="alta"></div>
          </div>
          <div class="row mb-2">
            <div class="col-4 mai">Teléfono</div>
            <div class="col-8 seg" v-html="tele"></div>
          </div>
        </div>
            
    </div>
    <div class="text-right col-12 pt-2">
      <span class="bg-warning btn btn-sm rotar">Girar Tarjeta</span>
    </div>
    </div>
  </div>

      <div class="tarjeta lados" style="display:none;">
   

          <div class="align-self-center text-center limitar" style="width: 100%">

              <img src="../assets/logoblanco.png" class="mb-4 logosub" />
              <br />

            <qrcode-vue :value="value" :size="size" level="H" class="qr" />
            <br />
            <br />
            <div class="text-right">
              <span class="bg-warning btn btn-sm rotar2">Girar tarjeta</span>
            </div>
          </div>
        </div>
    </div>



  </div>


</template>



<script>
  import QrcodeVue from 'qrcode.vue'
  import $ from 'jquery'

export default {

	data(){
		return {
        value: '',
        size: 200,
			nombre: '',
      dni: '',
      alta: '',
      tele: '',
      email: '',
      fnac: '',
      foto: '',
      elqr: '',
		}
	},
    components: {
      QrcodeVue,
    },
  mounted() {

    if (typeof this.$route.params.nombre !== 'undefined') {
      this.nombre = this.$route.params.nombre + ' ' + this.$route.params.apellido;
      this.dni = this.$route.params.dni;
      this.alta = this.$route.params.fechaAlta;
      this.tele = this.$route.params.telefono;
      this.email = this.$route.params.email;
      this.fnac = this.$route.params.nacimiento;
      this.foto = 'https://globalidenti.com/admin/usuarios/' + this.$route.params.uniqid + '.jpg';
      this.value = this.$route.params.tarjeta;
    } else {
        this.$router.push({ name: 'Home'});
    }


    $('.rotar').click(function(){
      $('.launo').fadeOut('fast', function(){
        $('.lados').fadeIn('fast');
        $('.launo').removeClass('d-flex');
        $('.lados').addClass('d-flex');
      });
    });
    $('.rotar2').click(function(){
      $('.lados').fadeOut('fast', function(){
        $('.lados').removeClass('d-flex');
        $('.launo').addClass('d-flex');
      });
    }); 
  }


}

   // 164322222
        // 041689300494

</script>

<style scoped>
.esfoto {    
  max-width: 100%;
    border-radius: 1em;
    box-shadow: 4px 4px 5px #7f0404;
  }

  h1 {
    color: #FFC;
    font-size: 1.5em;
    margin-top: 1em;
  }
  p {
    color: #FFF;
   
  }

  .logosub {
    width: 300px;
    max-width: 100%;
  }

  .datos div {
    color: white;
    font-size: 0.9em;
  }

  .datos div.mai {
    color: #FFF;
    font-weight: bold; 
    padding-right: 10px;
  }

  .seg {
        overflow: hidden;
    text-overflow: ellipsis;
  }

  .qr {
    border: 10px solid white;
  }
  .cruz {
    position: fixed;
    top: 10px;
    right: 20px;
    opacity: 1 !important;
  }
  .cruz button {
    color: white !important;
    opacity: 1 !important;
  }
  .cruz span {
    font-size: 50px;
    color: white !important;
    opacity: 1 !important;
  }
</style>
